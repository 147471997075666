<template>
  <c-box
    w="100%"
    max-width="1270px"
    mx="auto"
    bg="#FFF"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['16px', '30px']"
    :min-height="['unset', '74vh']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        pl="90px"
        py="20px"
        :paths="[
          {
            label: 'Dashboard',
            href: { name: 'client.index' }
          },
          {
            label: 'Profil',
            href: { name: 'client.profile' }
          },
          {
            label: 'Riwayat Program',
            href: { name: 'client.profile.programHistory' }
          },
          {
            label: 'Detail Riwayat Program',
            href: {
              name: 'client.profile.programDetail',
              params: { id: programId },
            }
          },
          {
            label: 'Detail Formulir Gizi',
            isCurrent: true,
          },
        ]"
      />
    </Portal>

    <QuizionaryContent 
      :role="authRole"
    />
  </c-box>
</template>

<script>
import QuizionaryContent from '@/components/quizionary/content'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'

export default {
  name: 'DetailClientQuizionaryPage',
  components: {
    QuizionaryContent,
    BreadcrumbPath,
  },
  computed: {
    programId() {
      return this.$route.params.programId
    },
  },
}
</script>
